/* eslint-disable no-irregular-whitespace */
import React from 'react'
import styles from './styles.module.scss'

export const variants = [
  { name: 'Zero-width space', text: '​', textToShow: <>Zero-width<span className={styles.space}>​</span>space</> },
  { name: 'Hair space', text: ' ', textToShow: <>Hair<span className={styles.space}> </span>space</> },
  { name: 'Six-per-em space', text: ' ', textToShow: <>Six-per-em<span className={styles.space}> </span>space</> },
  { name: 'Thin space', text: ' ', textToShow: <>Thin<span className={styles.space}> </span>space</> },
  { name: 'Punctuation space', text: ' ', textToShow: <>Punctuation<span className={styles.space}> </span>space</> },
  { name: 'Four-per-em space', text: ' ', textToShow: <>Four-per-em<span className={styles.space}> </span>space</> },
  { name: 'Three-per-em space', text: ' ', textToShow: <>Three-per-<span className={styles.space}> </span>space</> },
  { name: 'Figure space', text: ' ', textToShow: <>Figure<span className={styles.space}> </span>space</> },
  { name: 'En space', text: ' ', textToShow: <>En<span className={styles.space}> </span>space</> },
  { name: 'Em space', text: ' ', textToShow: <>Em<span className={styles.space}> </span>space</> },
  { name: 'Braille blank', text: '⠀', textToShow: <>Braille<span className={styles.space}>⠀</span>blank</> },
  { name: 'Caucasian astronaut (white space character)', text: '👨🏻‍🚀' }
]

export const tags = [
  { name: 'Unicode' },
  { name: 'Tools' },
  { name: 'Hacks' },
  { name: 'Copy and paste' },
  { name: 'Punctuation' }
]

export const table1 = [
  ['MacOS / Windows', 'ctr–shift–space'],
  ['HTML entity', '&nbsp;'],
  ['Unicode', 'U+00A0']
]

export const table2 = [
  ['HTML entity', '&#8202;'],
  ['Unicode', 'U+200A']
]

export const table3 = [
  ['HTML entity', '&thinsp;'],
  ['Unicode', 'U+2009']
]

export const table4 = [
  ['HTML entity', '&#8198;'],
  ['Unicode', 'U+2006']
]

export const table5 = [
  ['HTML entity', '&puncsp;'],
  ['Unicode', 'U+2008']
]

export const table6 = [
  ['HTML entity', '&numsp;'],
  ['Unicode', 'U+2007']
]

export const table7 = [
  ['HTML entity', '&#12288;'],
  ['Unicode', 'U+3000']
]

export const table8 = [
  ['HTML entity', '&emsp13;'],
  ['Unicode', 'U+2004']
]

export const table9 = [
  ['HTML entity', '&emsp14;'],
  ['Unicode', 'U+2005']
]

export const table10 = [
  ['HTML entity', '&emsp;'],
  ['Unicode', 'U+2003']
]

export const table11 = [
  ['HTML entity', '&ensp;'],
  ['Unicode', 'U+2002']
]

export const table12 = [
  ['HTML entity', '&#10240;'],
  ['Unicode', 'U+2800']
]

export const table13 = [
  ['HTML entity', '&#8203;'],
  ['Unicode', 'U+200B']
]

export const book1Link = 'https://www.amazon.co.uk/dp/0881792128/ref=as_li_tl?ie=UTF8&linkCode=g12&linkId=547bc19884ac115096e6d730117270a1&creativeASIN=0881792128&tag=drmrjr-21&creative=9325&camp=1789'
export const book2Link = 'https://www.amazon.co.uk/dp/0300111509/ref=as_li_tl?ie=UTF8&linkCode=g12&linkId=39f11d5b2f1d584e896900dbc4959633&creativeASIN=0300111509&tag=drmrjr-21&creative=9325&camp=1789'
