import React from 'react'
import { Link } from 'react-router-dom'

import { Card } from '../../../components/Card'
import { Table } from '../../../components/Table'
import { TextGroup } from '../../../components/TextGroup'
import { TextWithImage } from '../../../components/TextWithImage'
import { Title } from '../../../components/Title'
import { VariantsContainer } from '../../../components/VariantsContainer'

import { Quote } from './components/Quote'

import { CopyAndPaste } from '..'

import {
  book1Link, book2Link,
  table1,
  table10, table11, table12, table13,
  table2, table3, table4,
  table5, table6, table7, table8, table9,
  tags,
  variants
} from './constants'

import spaceImg from './assets/space.webp'

import styles from './styles.module.scss'
import { smallCapsTool, underlineTextTool } from '../../tools'

const Whitespace: React.FC = () => {
  return (
    <CopyAndPaste tags={tags} moreTools={[underlineTextTool, smallCapsTool]} subtitleClassName={styles.subtitle} titleClassName={styles.title}>
      <VariantsContainer>
        {variants.map(variant => (
          <Card
            className={styles.card}
            key={variant.name}
            title={variant.name}
            text={variant.text}
            textToShow={variant.textToShow}
            textFontSize={32}
            textAlignCenter
          />
        ))}
      </VariantsContainer>
      <TextGroup title='What are whitespace characters?' style={{ marginTop: 64 }}>
        <p>Whitespace characters denote the empty space between the characters you can actually see. </p>
        <p>The most common whitespace character is the word space. This is the one you get when you press the space bar.</p>
      </TextGroup>
      <Title size={3} style={{ marginTop: 48 }}>What is white space?</Title>
      <TextWithImage
        leftImage={<img src={spaceImg} style={{ width: 448 }} />}
        style={{ marginTop: 16 }}
      >
        <>
          <p>Generally speaking, white space refers to any empty area of a design or composition.</p>
          <p>It’s as simple as that, yet it’s often white space that separates good design from bad — almost always because there isn’t enough of it.</p>
        </>
      </TextWithImage>
      <Title size={3} style={{ marginTop: 48, marginBottom: 72 }}>Using white space online</Title>
      <Quote
        author='Robert Bringhurst'
        book={<a href={book1Link}>The Elements of Typographic Style</a>}
      >
        Big type, even huge type, can be beautiful and useful.
        But poise is usually far more important than size — and poise consists primarily of emptiness.
        Typographically, poise is made of whitespace.
      </Quote>
      <TextGroup style={{ marginTop: 32 }}>
        <p>
          The above holds especially true online. Web developers have a tendency to cram as much information on your screen as possible.
        </p>
        <p>
          However, the more crowded a composition, the less weight is carried by each individual element.
          When designing a website or a document, you can use whitespace characters to distinguish important information.
        </p>
      </TextGroup>
      <TextGroup title='White space on social media' style={{ marginTop: 48 }}>
        <p>
          In many places online (particularly social media), you’re limited to the whitespace characters designated by Unicode.
        </p>
        <p>
          Using them effectively can help your writing seem more authoritative.
        </p>
      </TextGroup>
      <TextGroup title='Word space' style={{ marginTop: 48 }}>
        The word space is the character you get when you press the spacebar. It’s the most common whitespace character.
      </TextGroup>
      <Quote author='Karen Cheng' book={<a href={book2Link}>Designing Type</a>} style={{ marginTop: 56 }}>
        The word space is a character with an inherent conflict of interest:
        the space must be wide enough to separate individual words, but narrow enough to encourage grouping into sentences and paragraphs.
      </Quote>
      <TextGroup style={{ marginTop: 32 }}>
        The word space is used to separate words and sentences. You know that already — but there’s still one pitfall to be aware of when using it.
      </TextGroup>
      <TextGroup subtitle='Don’t use two spaces between sentences.' style={{ marginTop: 32 }}>
        <p>
          Sometimes, people add two spaces after a period.
          There's a logic behind this — as the elements of your writing get bigger, so does the white space between them.
        </p>
        <p>
          letters → words → sentences → paragraphs
        </p>
        <p>
          However, a period is mostly white space. So sentences already have more space between them than words.
          The type designer of your chosen typeface will have taken this into account.
        </p>
      </TextGroup>
      <TextGroup title='No-break spaces' style={{ marginTop: 48 }}>
        <p>
          The word space also tells your word processor or web browser when it’s okay to break onto another line — but this isn’t always what you want.
        </p>
        <p>
          If it’s important to the flow of your writing that two words never be apart, you should insert a no-break space.
          You can space two words, like normal, without the possibility of them being on separate lines.
        </p>
      </TextGroup>
      <Table columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table1}
      </Table>
      <TextGroup title='Hair space' style={{ marginTop: 48 }}>
        Use the hair space when two characters are just a tiny bit closer than you’d like.
      </TextGroup>
      <TextGroup subtitle='Kerning on the fly' style={{ marginTop: 32 }}>
        You can use the hair space to add kerning (space between two individual letters) in circumstances where you normally can’t, like on social media.
        For example, the 𝔻𝕠𝕦𝕓𝕝𝕖 𝕊𝕥𝕣𝕦𝕔𝕜 characters generated by the <Link to='/fancy/'>fancy font generator</Link> can have terrible 𝕜𝕖𝕣𝕟𝕚𝕟𝕘.
        You can fix it using a hair space: 𝕜𝕖𝕣𝕟𝕚𝕟𝕘 becomes 𝕜𝕖𝕣  𝕟𝕚𝕟𝕘.
      </TextGroup>
      <Table columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table2}
      </Table>
      <TextGroup title='Thin space and six‑per‑em space' style={{ marginTop: 48 }}>
        <p>
          These two types of spaces are pretty much the same. Just like the hair space, they’re used to create a little extra space between characters.
        </p>
        <p>
          Both are roughly one sixth of an em in width. One specific use of the thin space is for separating quotes in nested quotations:
        </p>
        <p>
          Clive told me that “Prof. Reginald said, ‘Don't use nested quotations.’ ”
        </p>
      </TextGroup>
      <Table title='Thin Space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table3}
      </Table>
      <Table title='Six-per-em space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table4}
      </Table>
      <TextGroup title='Punctuation space' style={{ marginTop: 48 }}>
        The punctuation space takes up as much width as a period.
        It also acts as a non-breaking space. Some languages have whitespace before a punctuation mark.
        This space is used in those circumstances.
      </TextGroup>
      <Table columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table5}
      </Table>
      <TextGroup title='Figure space and ideographic space' style={{ marginTop: 48 }}>
        <p>A figure space is used solely for spacing fixed-width numerals. Its width is the same as one of the numerals.</p>
        <p>The ideographic space, similar to the figure space, is used with fixed-width CJK (Chinese, Japanese, and Korean) characters.</p>
      </TextGroup>
      <Table title='Figure space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table6}
      </Table>
      <Table title='Ideographic space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table7}
      </Table>
      <TextGroup title='Three-per-em space and four-per-em space' style={{ marginTop: 48 }}>
        The three-per-em and four-per-em spaces are one third and one fourth the width of an em, respectively.
      </TextGroup>
      <Table title='Three-per-em space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table8}
      </Table>
      <Table title='Four-per-em space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table9}
      </Table>
      <TextGroup title='Em space and en space' style={{ marginTop: 48 }}>
        <p>The em space is the width of a capital M, which also happens to be the height of most fonts.</p>
        <p>The en space is half the width of an em.</p>
      </TextGroup>
      <Table title='Em Space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table10}
      </Table>
      <Table title='En space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table11}
      </Table>
      <TextGroup title='Braille blank' style={{ marginTop: 48 }}>
        <p>
          This isn’t really a space. It’s meant to represent a braille pattern with six non-raised dots, so it displays as blank or sometimes six hollow dots. ⠀
        </p>
        <p>
          This means you can use this space in places where you normally can’t, like in URLs.
        </p>
      </TextGroup>
      <Table title='Braille blank' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table12}
      </Table>
      <TextGroup title='Zero-width space' style={{ marginTop: 48 }}>
        <p>
          The paradoxically named zero-width space lets your browser know when it’s okay to break a word onto a new line without hyphenating it.
        </p>
        <p>
          Useful, maybe, but the ability to use an invisible character with no width has serious cyber security implications.
        </p>
        <p>
          Hackers could create usernames, emails addresses, and websites that look identical to a human, but different to computers.
          Luckily, zero-width spaces are prohibited in email addresses or domain names — and it’s a well-known issue.
        </p>
        <p>
          On the upside, there are a few genuinely useful things you can do with a zero-width space:
        </p>
      </TextGroup>
      <TextGroup subtitle='Stop auto-linking' style={{ marginTop: 32 }}>
        You can stop social media sites from automatically turning a URL into a link without breaking up the text of the link.
      </TextGroup>
      <TextGroup subtitle='Empty form inputs' style={{ marginTop: 32 }}>
        You can use all these spaces to fill inputs with empty space, but the zero-width space seems to work in a few places the others don’t.
      </TextGroup>
      <TextGroup subtitle='Prevent information leaks' style={{ marginTop: 32 }}>
        <p>This idea comes from cyber-security guy <a href='https://www.zachaysan.com/writing/2017-12-30-zero-width-characters' target='_blank' rel='noreferrer'>Zach Aysan</a>.</p>
        <p>If your company has a leaker, you can add zero-width spaces to sensitive documents — hidden in different places for each of the recipients.</p>
        <p>Once the material is leaked, you can check it for zero-width spaces to find the identity of the leaker.</p>
      </TextGroup>
      <TextGroup subtitle='Send secret messages' style={{ marginTop: 32 }}>
        <p>You can use zero-width spaces to hide an encoded binary message in a piece of text. </p>
        <p>For instance: <i>T​h​e​re is​ a ​h​i​d​d​e​n e​moji ​in ​th​i​s.</i></p>
      </TextGroup>
      <Table title='Zero-width space' columnsWidth={[200, 200]} style={{ marginTop: 32 }}>
        {table13}
      </Table>
      <TextGroup title='Standing out online' style={{ marginTop: 32 }}>
        While most of these tricks are only useful to web developers, there are a few scenarios where these characters could help you stand out on social media.
      </TextGroup>
    </CopyAndPaste>
  )
}

export default Whitespace
