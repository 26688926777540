import React from 'react'

import { Title } from '../../../../../components/Title'

import QuoteIcon from '../../assets/download.svg'

import styles from './styles.module.scss'

type Props = {
  children: string | React.ReactNode
  author: string
  book: string | React.ReactNode
  style?: React.CSSProperties
}

export const Quote: React.FC<Props> = ({ children: text, author, book, style }) => {
  return (
    <div className={styles.container} style={style}>
      <QuoteIcon style={{ marginBottom: 32 }} />
      <p className={styles.text}>{text}</p>
      <Title center className={styles.author} size={4} h={7}>{author}</Title>
      <small className={styles.book}>{book}</small>
    </div>
  )
}
